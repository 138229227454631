import React from "react";
import styles from "./index.module.css";
import OrderDetails from "../OrderDetails";

const ListOrders = ({
  title,
  orders,
  updateOrder,
  partner,
  updatePaymentStatus,
  selectedOrderStatus,
}) => {
  const getTabItems = (orders, updateOrder, partner) => {
    return (
      <ul className={styles.orderList}>
        {orders.map((order, index) => (
          <OrderDetails
            key={index}
            order={order}
            updateOrder={updateOrder}
            updatePaymentStatus={updatePaymentStatus}
            partner={partner}
          />
        ))}
      </ul>
    );
  };

  return (
    <section id="latest-orders">
      <h2>{title}</h2>
      {getTabItems(orders, updateOrder, partner)}
      {orders.length === 0 && (
        <p
          style={{ textAlign: "center", margin: "30px" }}
        >{`No ${selectedOrderStatus === "filter by status" ? "" : selectedOrderStatus} orders`}</p>
      )}
    </section>
  );
};

export default ListOrders;
