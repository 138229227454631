import { useEffect } from "react";
import {
  onCreateQrcodeScanReport,
  onUpdateQrcodeScanReport,
} from "../graphql/subscriptions";
import { apiClient } from "../Router";

const useQrcodeLinkSubscription = ({ dispatch, slug, formattedDate }) => {
  useEffect(() => {
    const handleSubscription = ({ operation, name, type, filter }) => {
      return apiClient
        .graphql({
          query: operation,
          variables: {
            filter,
          },
        })
        .subscribe({
          next: (qrcodeScanData) => {
            const qrcodeLink = qrcodeScanData.data[`${name}`];

            const filteredSessionData = [...qrcodeLink.sessionData].filter(
              (session) => {
                const sessionDate = new Date(session.updatedAt);
                const date = new Date(formattedDate);
                const date1 = date.toISOString().split("T")[0];
                const date2 = sessionDate.toISOString().split("T")[0];
                return date1 === date2;
              },
            );
            dispatch({
              type,
              qrcodeLink: {
                ...qrcodeLink,
                sessionData: filteredSessionData,
              },
            });
          },
          error: (error) =>
            console.log(
              `error: ${operation.name}Subscription`,
              error?.error?.errors[0],
            ),
        });
    };

    const subscribe = () => {
      const newQrcodeScanSubscription = handleSubscription({
        operation: onCreateQrcodeScanReport,
        name: "onCreateQrcodeScanReport",
        type: "ADD_QRCODE_LINK",
        notification: "New menu scanned",
        filter: {
          qrcodeId: { contains: slug },
          updatedAt: {
            ge: formattedDate,
          },
        },
      });
      const updateQrcodeScanSubscription = handleSubscription({
        operation: onUpdateQrcodeScanReport,
        name: "onUpdateQrcodeScanReport",
        type: "UPDATE_QRCODE_LINK",
        notification: "New menu scanned",
        filter: {
          qrcodeId: { contains: slug },
          updatedAt: {
            ge: formattedDate,
          },
        },
      });

      return { newQrcodeScanSubscription, updateQrcodeScanSubscription };
    };

    let subscriptions = subscribe();

    const handleOnline = () => {
      subscriptions.newQrcodeScanSubscription.unsubscribe();
      subscriptions.updateQrcodeScanSubscription.unsubscribe();
      subscriptions = subscribe();
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOnline);

    return () => {
      subscriptions.newQrcodeScanSubscription.unsubscribe();
      subscriptions.updateQrcodeScanSubscription.unsubscribe();
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOnline);
    };
  }, [dispatch, formattedDate, slug]);
};

export default useQrcodeLinkSubscription;
