import axios from "axios";

export const generateOrderPDF = async ({ startDate, endDate, accountId }) => {
  try {
    const response = await axios.post(
      "https://p0osgl6ckg.execute-api.eu-west-2.amazonaws.com/prod/generate-pdf",
      {
        startDate,
        endDate,
        accountId,
      },
      {
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error generating PDF", error);
    return null;
  }
};
