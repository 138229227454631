import React, { createContext } from "react";
import { ConfigProvider } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { generateClient } from "@aws-amplify/api";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { AppFooter } from "./components";
import "@aws-amplify/ui-react/styles.css";
import { usePartner } from "./hooks";
import { getRoutes } from "./getRoutes";

export const apiClient = generateClient();
export const AppContext = createContext();

const Router = ({ user }) => {
  const contextValue = usePartner(user, apiClient);

  const routes = getRoutes(contextValue);
  const router = createBrowserRouter(routes);

  return (
    <AppContext.Provider value={contextValue}>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "rgba(57, 47, 47, 1)",
            borderRadius: 2,

            // Alias Token
            // colorBgContainer: 'rgba(57, 47, 47, 1);',
          },
        }}
      >
        <RouterProvider router={router} />
        <AppFooter />
      </ConfigProvider>
    </AppContext.Provider>
  );
};

export default withAuthenticator(Router, {
  hideSignUp: true,
  usernameAlias: "email",
  withGreetings: true,
});
