import React, { useContext, useReducer, useState, useMemo } from "react";
import { notification } from "antd";
import styles from "./index.module.css";
import { AppContext } from "../../Router.js";
import { useFetchOrders, useOrderSubscription } from "../../hooks";
import Loading from "../Loading";
import ListOrders from "../ListOrders";
import OrderActions from "../OrderActions";
import OrderFilters from "../OrderFilters";
import {
  getListOrderTitle,
  initialState,
  reducer,
  getStartOfToday,
  getEndOfToday,
  filterOrders,
} from "../../utils/orderUtils";
import { generateOrderPDF } from "../../utils/api";
import { apiClient } from "../../Router";
import { updateOrder as UpdateOrder } from "../../graphql/mutations";

const Orders = () => {
  const { userAttributes, partner, user } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [api, contextHolder] = notification.useNotification();
  const [selectedOrderStatus, setSelectedOrderStatus] =
    useState("filter by status");
  const [selectedDay, setSelectedDay] = useState("today");
  const [downloading, setDownloading] = useState(false);

  const dates = useMemo(() => {
    return {
      startDate: getStartOfToday(selectedDay),
      endDate: getEndOfToday(selectedDay),
    };
  }, [selectedDay]);

  const { fetchOrders, isLoading } = useFetchOrders({
    dates,
    dispatch,
    api,
    accountId: userAttributes["custom:accountId"],
    userAttributes,
    userLocation: userAttributes["custom:location"] || null,
  });
  useOrderSubscription({
    dispatch,
    userAttributes,
    api,
    accountId: userAttributes["custom:accountId"],
    userLocation: userAttributes["custom:location"] || null,
  });

  const updateOrder = async (id, value) => {
    const index = state.orders.findIndex((o) => o.id === id);
    const orders = [...state.orders];
    orders[index]["status"] = value;

    const variables = {
      input: { id, updatedBy: user.username, status: value },
    };
    dispatch({ type: "SET_ORDERS", orders });
    try {
      await apiClient.graphql({
        query: UpdateOrder,
        variables,
      });
    } catch (err) {
      console.log("error: ", err);
    }
  };

  const updatePaymentStatus = async (id, status) => {
    const index = state.orders.findIndex((o) => o.id === id);
    const orders = [...state.orders];
    orders[index]["paymentDetails"] = {
      ...orders[index]["paymentDetails"],
      status,
    };

    const variables = {
      input: {
        id,
        updatedBy: user.username,
        paymentDetails: {
          ...orders[index]["paymentDetails"],
          status,
        },
      },
    };
    dispatch({ type: "SET_ORDERS", orders });
    try {
      await apiClient.graphql({
        query: UpdateOrder,
        variables,
      });
      console.log("order successfully updated!");
    } catch (err) {
      console.log("error: ", err);
    }
  };

  const handleStatusChange = (value) => setSelectedOrderStatus(value);
  const refresh = async () => fetchOrders();

  if (!partner) return null;
  if (isLoading) return <div>Loading...</div>;
  const filteredOrders = filterOrders(
    state.orders,
    selectedDay,
    selectedOrderStatus,
  );

  const getOrdersInPdf = async () => {
    try {
      setDownloading(true);
      const response = await generateOrderPDF({
        startDate: getStartOfToday(selectedDay),
        endDate: getEndOfToday(selectedDay),
        accountId: userAttributes["custom:accountId"],
      });
      setDownloading(false);
      return response.url;
    } catch (error) {
      console.error("Error getting orders in pdf format", error);
      setDownloading(false);
      return null;
    }
  };

  const onSendToPrinterClicked = async () => {
    const pdfUrl = await getOrdersInPdf();
    if (!pdfUrl) {
      api.error({
        message: "Error getting orders",
        description: "Error getting orders in pdf format",
      });
      return;
    }
    const newWindow = window.open(pdfUrl, "_blank");
    if (!newWindow) {
      alert("Please allow popups for this website");
    }
  };

  return (
    <main className={styles.container}>
      {downloading ? (
        <Loading />
      ) : (
        <>
          <div className={styles.spacer}>
            {contextHolder}
            <OrderActions
              refresh={refresh}
              selectedDay={selectedDay}
              handleDateChange={(value) => {
                setSelectedDay(value);
                setSelectedOrderStatus("filter by status");
              }}
              availableOrdersLength={state.orders.length}
              onDownloadClicked={() => onSendToPrinterClicked()}
            />
          </div>
          <OrderFilters
            selectedOrderStatus={selectedOrderStatus}
            handleStatusChange={handleStatusChange}
            availableOrdersLength={state.orders.length}
          />
          <ListOrders
            orders={filteredOrders}
            updateOrder={updateOrder}
            updatePaymentStatus={updatePaymentStatus}
            partner={partner}
            selectedOrderStatus={selectedOrderStatus}
            title={getListOrderTitle(selectedOrderStatus, filteredOrders)}
          />
        </>
      )}
    </main>
  );
};

export default Orders;
