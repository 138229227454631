import { useEffect, useState } from "react";
import { listQrcodeLinks } from "../graphql/queries";
import { apiClient } from "../Router";
const MAX_MINIMUM_QRCODE_LIMIT = 100000000;

function filterSessionsByDate(qrcodeLinks, formattedDate) {
  const date = new Date(formattedDate);
  const date1 = date.toISOString()?.split("T")[0] || "";

  return qrcodeLinks.map((item) => {
    const filteredSessionData = item.sessionData.filter((session) => {
      const sessionDate = new Date(session.updatedAt);
      const date2 = sessionDate.toISOString()?.split("T")[0] || "";
      return date1 === date2;
    });

    return {
      ...item,
      sessionData: filteredSessionData,
    };
  });
}

const useFetchQrcodeLinks = ({ dispatch, slug, formattedDate }) => {
  const [isLoadingQrcodeLinks, setIsLoading] = useState(true);
  const fetchQrcodelinks = async () => {
    setIsLoading(true);
    try {
      const { data } = await apiClient.graphql({
        query: listQrcodeLinks,
        variables: {
          filter: {
            qrcodeId: { contains: slug },
            updatedAt: {
              ge: formattedDate,
            },
          },
          limit: MAX_MINIMUM_QRCODE_LIMIT,
        },
      });

      dispatch({
        type: "SET_QRCODE_LINKS",
        qrcodeLinks: filterSessionsByDate(
          data.listQrcodeScanReports.items,
          formattedDate,
        ),
      });
    } catch (err) {
      dispatch({ type: "ERROR" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchQrcodelinks();
  }, [dispatch, formattedDate, slug]);
  return { isLoadingQrcodeLinks, fetchQrcodelinks };
};

export default useFetchQrcodeLinks;
