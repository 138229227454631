export const getOrdersByStatus = (orders, status) => {
  return [...orders].filter((order) => {
    if (status === "filter by status") return true;
    return order.status === status;
  });
};

export const getOrderByDate = (orders, selectedDay) => {
  return orders.filter((order) => {
    const orderDate = new Date(order.createdAt);
    const startOfToday = getStartOfToday(selectedDay);
    const endOfToday = getEndOfToday(selectedDay);

    return orderDate >= startOfToday && orderDate <= endOfToday;
  });
};

export const filterOrders = (orders, selectedDay, selectedOrderStatus) => {
  return getOrdersByStatus(
    getOrderByDate(orders, selectedDay),
    selectedOrderStatus,
  );
};

export const getListOrderTitle = (selectedOrderStatus, filteredOrders) => {
  switch (selectedOrderStatus) {
    case "filter by status":
      return `All Orders (${filteredOrders.length})`;
    case "placed":
      return `Placed Orders (${filteredOrders.length})`;
    case "confirmed":
      return `Confirmed Orders (${filteredOrders.length})`;
    case "cancelled":
      return `Cancelled Orders (${filteredOrders.length})`;
    case "transit":
      return `Transit Orders (${filteredOrders.length})`;
    case "delivered":
      return `Delivered Orders (${filteredOrders.length})`;
    default:
      return "All Orders";
  }
};

export const initialState = {
  orders: [],
  loading: true,
  status: "placed",
  error: false,
  form: { name: "", description: "" },
};

export function reducer(state, action) {
  switch (action.type) {
    case "ADD_ORDER": {
      return { ...state, orders: [action.order, ...state.orders] };
    }
    case "RESET_FORM":
      return { ...state, form: initialState.form };
    case "SET_ORDERS":
      return { ...state, orders: action.orders, loading: false };
    case "SET_INPUT":
      return { ...state, form: { ...state.form, [action.name]: action.value } };

    case "UPDATE_ORDER": {
      const { orders } = state;
      const index = state.orders.findIndex((o) => o.id === action.order.id);

      if (index > -1) {
        orders[index].paymentStatus = action.order.paymentStatus;
        orders[index].status = action.order.status;
        orders[index].feedback = action.order.feedback;
      } else {
        orders.push(action.order);
      }

      return { ...state, orders };
    }
    case "SET_FILTER":
      return { ...state, status: action.status };
    case "ERROR":
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

export function getStartOfToday(selectedDayOrDate) {
  let date = new Date();
  switch (selectedDayOrDate) {
    case "yesterday":
      date.setDate(date.getDate() - 1);
      break;
    case "last14days":
      date.setDate(date.getDate() - 14);
      break;
    case "last7days":
      date.setDate(date.getDate() - 7);
      break;
    case "last30days":
      date.setDate(date.getDate() - 30);
      break;
    case "last90days":
      date.setDate(date.getDate() - 90);
      break;
    default:
      date = new Date();
  }
  date.setHours(0, 0, 0, 0);
  const timeZoneOffset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
  const localDate = new Date(date.getTime() - timeZoneOffset);
  return localDate;
}

export function getEndOfToday(selectedDayOrDate) {
  let date;
  switch (selectedDayOrDate) {
    case "today":
      date = new Date();
      break;
    case "yesterday":
      date = new Date();
      date.setDate(date.getDate() - 1);
      break;
    case "last7days":
    case "last14days":
    case "last30days":
    case "last90days":
      date = new Date();
      break;
    default:
      date = new Date();
  }
  date.setHours(23, 59, 59, 999);
  const timeZoneOffset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
  const localDate = new Date(date.getTime() - timeZoneOffset);
  return localDate;
}
