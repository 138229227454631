import React, { useContext } from "react";
import { AppContext } from "../../Router";
import { capitalizeText } from "../../utils";
import "./index.css";

const overviewConfig = [
  {
    name: "Top selling",
    key: "topSelling",
    order: 1,
    orderEnabled: true,
  },
  {
    name: "Total Orders",
    key: "totalOrders",
    order: 2,
    orderEnabled: true,
  },
  {
    name: "Total Sales",
    key: "totalSales",
    order: 3,
    orderEnabled: true,
  },
  {
    name: "Menu scans",
    key: "totalMenuViews",
    order: 4,
    orderEnabled: false,
    scanMetric: true,
  },
  {
    name: "Top scan",
    key: "topViewedQrcodeLinkId",
    order: 5,
    orderEnabled: false,
    scanMetric: true,
  },
];

const Overview = ({
  totalOrders = 0,
  totalSales = 0,
  orders = [],
  qrcodeLinks = [],
}) => {
  const { partner } = useContext(AppContext);
  const currencyConfig = JSON.parse(partner.config.currencyConfig);
  const isOrderingEnabled = partner.config.ordering;

  function formatSales(number) {
    if (number >= 1_000_000_000) {
      return (number / 1_000_000_000).toFixed(0) + "B";
    } else if (number >= 1_000_000) {
      return (number / 1_000_000).toFixed(0) + "M";
    } else if (number >= 1_000) {
      return (number / 1_000).toFixed(0) + "K";
    } else {
      return number.toString();
    }
  }

  const getTopSelling = () => {
    let sales = {};
    if (!orders || orders.length === 0) return "N/A";
    orders.forEach((order) => {
      order.sales.forEach((sale) => {
        if (sales[sale.name]) {
          sales[sale.name] += sale.quantity;
        } else {
          sales[sale.name] = sale.quantity;
        }
      });
    });

    const topSelling = Object.keys(sales).reduce((a, b) =>
      sales[a] > sales[b] ? a : b,
    );

    return capitalizeText(topSelling);
  };

  function getQueryParams(queryString) {
    const params = new URLSearchParams(queryString);
    const t = params.get("t");
    const l = params.get("l");
    return { t, l };
  }

  function getTopScannedLocation(data) {
    const locationCounts = {};

    data.forEach((item) => {
      item.sessionData.forEach((session) => {
        const location = session.scannedLocation;
        const count = session.scanCount;

        if (locationCounts[location]) {
          locationCounts[location] += count;
        } else {
          locationCounts[location] = count;
        }
      });
    });

    let topLocation = null;
    let maxCount = 0;

    for (const location in locationCounts) {
      if (locationCounts[location] > maxCount) {
        maxCount = locationCounts[location];
        topLocation = location;
      }
    }

    return topLocation;
  }

  const getTopViewedQrcodeLinkId = () => {
    let views = {};
    if (!qrcodeLinks || qrcodeLinks.length === 0) return "N/A";
    qrcodeLinks.forEach((qrcodeLink) => {
      views[qrcodeLink.qrcodeId] = qrcodeLink.sessionData.reduce(
        (acc, curr) => acc + curr.scanCount,
        0
      );
    });

    const topViewed = Object.keys(views).reduce((a, b) =>
      views[a] > views[b] ? a : b
    );

    const [, formatted] = (topViewed?.split("?") || []);
    const { t } = getQueryParams(formatted);

    if (t) {
      return `Table ${t}`;
    }

    const topLocation = getTopScannedLocation(qrcodeLinks);
    const [type, name] = (topLocation?.split("-") || []);
    if (type === "table") {
      return `Table ${name}`;
    }

    if (type === "room") {
      return `Room ${name}`;
    }

    return "N/A";
  };

  const getTotalMenuViews = () => {
    if (!qrcodeLinks || qrcodeLinks.length === 0) return 0;
    return qrcodeLinks.reduce(
      (acc, curr) =>
        acc + curr.sessionData.reduce((acc, curr) => acc + curr.scanCount, 0),
      0,
    );
  };

  const getMetricValue = (key) => {
    switch (key) {
      case "topSelling":
        return getTopSelling();
      case "totalOrders":
        return totalOrders;
      case "totalSales":
        return `${currencyConfig.symbol}${formatSales(totalSales)}`;
      case "totalMenuViews":
        return getTotalMenuViews();
      case "topViewedQrcodeLinkId":
        return getTopViewedQrcodeLinkId();
      default:
        return "N/A";
    }
  };

  const renderMetrics = () => {
    return overviewConfig
      .filter(
        (config) =>
          config.orderEnabled === isOrderingEnabled || config.scanMetric,
      )
      .sort((a, b) => a.order - b.order)
      .map((config) => {
        return (
          <div key={config.key} className={`metric ${config.key}`}>
            <dt className="metric-label">{config.name}</dt>
            <dd className="metric-number">{getMetricValue(config.key)}</dd>
          </div>
        );
      });
  };

  return (
    <section id="overview">
      <h2>Overview</h2>
      <dl className="metrics">{renderMetrics()}</dl>
    </section>
  );
};

export default Overview;
