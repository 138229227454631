import React, { useContext } from "react";
import { AppContext } from "../../Router";
import { useLocation } from "react-router-dom";
import "./index.css";

const Navigation = () => {
  const { user, userAttributes, signOut, partner } = useContext(AppContext);
  const location = useLocation();

  const pathname = location.pathname;

  const handleSignOut = (event) => {
    event.preventDefault();
    signOut();
  };

  return (
    <nav className="header__nav">
      <ul>
        {userAttributes.admin && (
          <>
            <li className={pathname === "/" ? "active_route" : ""}>
              <a href="/">Dashboard</a>
            </li>
            <li className={pathname === "/menus" ? "active_route" : ""}>
              <a href="/menus">Menus</a>
            </li>
          </>
        )}
        {partner?.config?.ordering && (
          <li className={pathname === "/orders" ? "active_route" : ""}>
            <a href="/orders">Orders</a>
          </li>
        )}
        {user && (
          <li>
            <a data-test-id="logout-link" href="/" onClick={handleSignOut}>
              Logout
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
