import React from "react";

const AppFooter = () => {
  return (
    <footer>
      <p>&copy; 2025 Qrcodeflip.com</p>
    </footer>
  );
};

export default AppFooter;
