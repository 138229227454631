import { useEffect, useState } from "react";
import { listOrders } from "../graphql/queries";
import { apiClient } from "../Router";

const MAX_MINIMUM_ORDER_LIMIT = 100000;

const useFetchOrders = ({
  dispatch,
  accountId,
  userLocation,
  userAttributes,
  dates,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { startDate, endDate } = dates;

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const ordersData = await apiClient.graphql({
        query: listOrders,
        variables: {
          filter: {
            accountId: {
              eq: accountId,
            },
            createdAt: {
              between: [startDate, endDate],
            },
          },
          limit: MAX_MINIMUM_ORDER_LIMIT,
        },
      });
      if (userLocation && !userAttributes.admin) {
        const filteredOrders = ordersData.data.listOrders.items.filter(
          (order) =>
            order?.orderSource?.sectionName?.toLowerCase() ===
            userLocation?.toLowerCase(),
        );
        dispatch({
          type: "SET_ORDERS",
          orders: filteredOrders,
        });
        setIsLoading(false);
        return;
      }
      dispatch({
        type: "SET_ORDERS",
        orders: ordersData.data.listOrders.items,
      });
    } catch (err) {
      dispatch({ type: "ERROR" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, [dispatch, userLocation, userAttributes, dates]);
  return { isLoading, fetchOrders };
};

export default useFetchOrders;
