import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { List, Space } from "antd";
import { getDisplayPrice } from "../../Utils";
import { capitalizeText } from "../../../../utils";
const IconText = ({ icon, text, onIconClicked, dataTestId }) => (
  <Space onClick={onIconClicked} data-test-id={dataTestId}>
    {React.createElement(icon)}
    {text}
  </Space>
);

const getPriceItems = (menu, currencyConfig) => {
  if (menu.locationPrices) {
    return menu.locationPrices.map((locationPrice) => {
      return (
        <div key={locationPrice.location} data-test-id="menu-price">
          <div>
            Price @{capitalizeText(locationPrice.location)}:{" "}
            {getDisplayPrice({
              priceInPence: locationPrice.price,
              withCurrencySymbol: true,
              currencyConfig,
            })}
          </div>
        </div>
      );
    });
  }
  return (
    <div data-test-id="menu-price">
      {getDisplayPrice({
        priceInPence: menu.price,
        withCurrencySymbol: true,
        currencyConfig,
      })}
    </div>
  );
};

const ListMenusComponent = ({
  menus = [],
  onEditMenuClicked,
  currencyConfig,
  onMenuDeleteClicked,
}) => {
  return (
    <section>
      <List
        itemLayout="horizontal"
        pagination={{
          pageSize: 20,
        }}
        style={{
          height: "100%",
          width: "100%",
        }}
        dataSource={menus}
        data-test-id="menus-list"
        renderItem={(item) => (
          <List.Item
            actions={[
              <IconText
                icon={DeleteOutlined}
                text="Delete"
                dataTestId="delete-menu-icon"
                key={`ist-vertical-star-o-${item.id}`}
                onIconClicked={() => onMenuDeleteClicked(item)}
              />,
              <IconText
                icon={EditOutlined}
                text="Edit"
                dataTestId="edit-menu-icon"
                key="list-vertical-star-o"
                onIconClicked={() => onEditMenuClicked(item)}
              />,
            ]}
          >
            <List.Item.Meta
              title={capitalizeText(item.name || "")}
              data-test-id="menu-title"
              description={
                <>
                  <div data-test-id="menu-description">
                    {capitalizeText(item.description || "")}
                  </div>
                  {getPriceItems(item, currencyConfig)}
                </>
              }
            />
          </List.Item>
        )}
      />
    </section>
  );
};

export default ListMenusComponent;
