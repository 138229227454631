export const capitalizeText = (text = "") => {
  text = text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return text;
};
export const formatCurrency = ({ price, language, currency }) => {
  return new Intl.NumberFormat(language, {
    style: "currency",
    currency,
  }).format(price);
};
export const getDisplayPrice = ({
  priceInPence = 0,
  withCurrencySymbol = false,
  currencyConfig,
}) => {
  const price = Number(priceInPence / 100);

  let config = currencyConfig;

  if (typeof currencyConfig === "string") {
    config = JSON.parse(currencyConfig);
  }

  if (withCurrencySymbol) {
    return formatCurrency({
      ...config,
      price,
    });
  }

  return price;
};

export const statuses = {
  PLACED: "placed",
  CONFIRMED: "confirmed",
  TRANSIT: "transit",
  DELIVERED: "delivered",
  CANCELLED: "cancelled",
};

export { convertToMarkDown } from "../utils/convertToMarkDown";

export { normalizeQrcodeLinks } from "../utils/query";

export { getOrdersByStatus, getListOrderTitle } from "../utils/orderUtils";

export { generateOrderPDF } from "../utils/api";
