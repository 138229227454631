import React from "react";
import { Button, Select } from "antd";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import styles from "./index.module.css";

const OrderActions = ({
  refresh,
  selectedDay,
  handleDateChange,
  onDownloadClicked,
  availableOrdersLength,
}) => {
  return (
    <div className={styles.container}>
      <Button
        icon={<ReloadOutlined />}
        onClick={refresh}
        className={styles.no_margin_button}
        disabled={selectedDay !== "today"}
      />
      <Select
        defaultValue={selectedDay}
        style={{ width: 120 }}
        onChange={handleDateChange}
        className={styles.select_container}
        options={[
          { value: "today", label: "Today" },
          { value: "yesterday", label: "Yesterday" },
          { value: "last7days", label: "Last 7 days" },
          { value: "last14days", label: "Last 14 days" },
          { value: "last30days", label: "Last 30 days" },
          { value: "last90days", label: "Last 90 days" },
        ]}
      />
      <Button
        type="primary"
        className={styles.no_margin_button}
        disabled={availableOrdersLength === 0}
        icon={<DownloadOutlined />}
        onClick={onDownloadClicked}
      >
        Download
      </Button>
    </div>
  );
};

export default OrderActions;
