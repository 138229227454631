import React, { useState, useEffect, useContext } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { listMenus as ListMenus } from "../../graphql/queries";
import {
  updateMenu as UpdateMenu,
  createAuditLog as CreateAuditLog,
} from "../../graphql/mutations";
import ListMenusComponent from "./components/ListMenus";
import Loading from "../Loading";
import MenuModal from "./components/menu-modal";
import MenusHeader from "./components/Header";
import styles from "./index.module.css";
import { getDifference } from "./Utils";
import { AppContext, apiClient } from "../../Router";

const MENU_MAX_LIMIT = 10000;
const DELETED_MENUS_ACCOUNT_ID = "100000";

const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

function Menus() {
  const { partner, user, userAttributes } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    async function fetchMenus() {
      setIsLoading(true);
      try {
        const { data } = await apiClient.graphql({
          query: ListMenus,
          variables: {
            filter: {
              accountId: { eq: userAttributes["custom:accountId"] },
              categoryName: { ne: "TABLE RESERVATION" },
            },
            limit: MENU_MAX_LIMIT,
          },
        });
        const sortedMenus = data.listMenus.items.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        setMenus(sortedMenus);
      } catch (error) {
        console.error("Error fetching menus:", error);
      }
      setIsLoading(false);
    }

    fetchMenus();
    try {
      recordViewAuditLog();
    } catch (error) {
      console.error("Error fetching menus:", error);
    }

  }, [partner, user, userAttributes]);

  const handleMenuEdit = (menu) => {
    setIsOpen(true);
    setSelectedMenu(menu);
  };

  const handleModalClose = () => {
    setIsOpen(false);
    setSelectedMenu(null);
  };

  const showDeleteConfirm = (menu) => {
    modal.confirm({
      title: "Are you sure you want to delete this menu?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => handleMenuDelete(menu),
    });
  };

  const handleMenuDelete = async (menu) => {
    setIsConfirmLoading(true);
    try {
      await apiClient.graphql({
        query: UpdateMenu,
        variables: {
          input: {
            id: menu.id,
            accountId:
              DELETED_MENUS_ACCOUNT_ID /*  Add DELETED_MENUS_ACCOUNT_ID to simulate deletion */,
          },
        },
      });
      setMenus((prevMenus) => prevMenus.filter((m) => m.id !== menu.id));
      try {
        await recordUpdateAuditLog(menu, { ...menu, isDeleted: true });
      } catch (error) {
        console.error("Error creating audit menu:", error);
      }
    } catch (error) {
      console.error("Error deleting menu:", error);
    }
    setIsConfirmLoading(false);
  };

  const handleMenuUpdate = async (menu) => {
    if (!deepEqual(menu, selectedMenu)) {
      setIsConfirmLoading(true);
      try {
        await updateMenus(menu);
        setMenus((prevMenus) =>
          prevMenus.map((m) => (m.id === menu.id ? menu : m)),
        );
        try {
          await recordUpdateAuditLog(selectedMenu, menu);
        } catch (error) {
          console.error("Error creating audit menu:", error);
        }
      } catch (error) {
        console.error("Error updating menu:", error);
      }
      setIsConfirmLoading(false);
    }
    handleModalClose();
  };

  const updateMenus = async (menu) => {
    const {
      id,
      price,
      description = "",
      name,
      imageUrl,
      locationPrices,
    } = menu;

    await apiClient.graphql({
      query: UpdateMenu,
      variables: {
        input: {
          id,
          price,
          name,
          description,
          imageUrl,
          locationPrices,
        },
      },
    });
  };

  const recordUpdateAuditLog = async (oldMenu, newMenu) => {
    await apiClient.graphql({
      query: CreateAuditLog,
      variables: {
        input: {
          changeTime: new Date().getTime().toString(),
          user: user.username,
          update: JSON.stringify({
            id: oldMenu.id,
            ...getDifference(oldMenu, newMenu),
            name: oldMenu.name,
          }),
          field: "menu",
          accountId: partner.accountId,
        },
      },
    });
  };

  const handleSearchChange = (value) => {
    setSelectedCategory(null);
    setSearchTerm(value);
  };

  const filteredMenus = [...menus].filter((menu) => {
    return searchTerm
      ? menu.name.toLowerCase().includes(searchTerm.toLowerCase())
      : selectedCategory
        ? menu.subCategoryName === selectedCategory
        : true;
  });


  const recordViewAuditLog = async () => {
    await apiClient.graphql({
      query: CreateAuditLog,
      variables: {
        input: {
          accountId: userAttributes["custom:accountId"],
          action: "VIEWED_MENUS",
          changeTime: new Date().getTime().toString(),
          user: user.username,
          update: {},
          field: "menu",
        },
      },
    });
  };

  if (!partner) return null;

  return (
    <main className={styles.container} data-test-id="menu-page">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <MenusHeader
            onSearch={handleSearchChange}
            onCategoryChanged={(category) => setSelectedCategory(category)}
            menus={[...menus]}
          />
          <ListMenusComponent
            menus={filteredMenus}
            onEditMenuClicked={handleMenuEdit}
            onMenuDeleteClicked={showDeleteConfirm}
            currencyConfig={partner.config.currencyConfig}
          />
          {contextHolder}
          {isOpen && (
            <MenuModal
              confirmLoading={isConfirmLoading}
              open={isOpen}
              currencyConfig={JSON.parse(partner.config.currencyConfig)}
              handleOk={handleMenuUpdate}
              handleCancel={handleModalClose}
              selectedMenu={selectedMenu}
            />
          )}
        </>
      )}
    </main>
  );
}

export default Menus;
