import React from "react";
import { Orders, Menus, Dashboard, AppHeader } from "./components";
import ScrollToTop from "./components/ScrollToTop";

export const getRoutes = (contextValue) => {
  if (!contextValue.partner) {
    return [{ path: "*", element: <div>Loading...</div> }];
  }

  const routes = [];
  const { partner, userAttributes } = contextValue;
  const isOrderingEnabled = partner?.config?.ordering;
  const isAdmin = userAttributes?.admin;

  const wrapWithHeader = (Component) => (
    <>
      <ScrollToTop />
      <AppHeader />
      {Component}
    </>
  );

  if (isOrderingEnabled) {
    routes.push({
      path: "/orders",
      element: wrapWithHeader(<Orders />),
    });

    if (!isAdmin) {
      routes.push({
        path: "/",
        element: wrapWithHeader(<Orders />),
      });
    }
  }

  if (isAdmin) {
    routes.push({
      path: "/",
      element: wrapWithHeader(<Dashboard />),
    });
    routes.push({
      path: "/menus",
      element: wrapWithHeader(<Menus />),
    });
  }

  // Ensure at least one route is always returned
  if (routes.length === 0) {
    routes.push({
      path: "*",
      element: wrapWithHeader(<div>Page not found</div>),
    });
  }

  return routes;
};
